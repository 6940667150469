<template>
    <div class="box">
        <div class="box-h2">福建北峰通信科技股份有限公司</div>
        <div class="content">
            <p>北福建北峰通信科技股份有限公司（证券代码:872057），创立于1989年，是聚焦专业无线通信领域服务于全球的专网解决方案和设备提供商。</p>

            <p>公司坚持专注于专网无线通信领域的研究与探索，率先发展专网数字集群通信技术、宽窄带自组网技术、融合通信技术。产品线覆盖从工商领域到公共安全领域，从政企市场到军警市场等专网通信用户。推动通信与管理的融合应用，提高全方位应急通信与指挥调度能力，提升响应速度和组织效能。
            </p>
            <p><img src="../../../assets/img/3ea6beec64369c2642b92c6726f1epng.png" /></p>
            <p>为了提升综合竞争力，继续深耕专网市场，加强研发团队的建设，持续将年销售收入的15%以上投入研发，目前，研发人员占比30%、拥有100多项知识产权专利。同时已在全国建有泉州、福州、北京、广州4大研发中心。</p>
            <p>北峰建立了覆盖全球大部分地区的营销网络，遍布全球的500多家分销商、集成商和合作伙伴紧密协作，产品销往全球50多个国家及地区。</p>
            <p>北峰以"专业通信领域实现客户所需"为己任，致力于为全球客户定制专属集群通信解决方案。</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "zbItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {

        }
    },
    created () { },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.box {
    padding: 60px 30px 30px;
    background: #fff;

    .box-h2 {
        text-align: center;
        color: #2E3033;
        font-size: 28px;
        margin-bottom: 30px;
    }

    .content {
        p {
            margin-bottom: 20px;
            line-height: 30px;
        }

        img {
            max-width: 100%;
        }
    }
}
</style>