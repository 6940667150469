<template>
    <router-link :to="{ name: 'productsDetails', params: { id: item.id } }" class="zb-item flex align-items">
        <div class="zb-item-img mr-15">
            <el-image :src="item.pic" lazy></el-image>
        </div>
        <div class="zb-item-content">
            <h3 class="text-hide">{{ item.title }}</h3>
            <div class="desc text-hide-2">{{ item.description }}</div>
            <div class="attachment" v-if="item.attachment">附件：<span>{{ item.attachment }}</span></div>
            <div class="footer flex">
                <span class="mr-30" v-if="item.category">所属供应商：{{ item.category }}</span>
                <time>{{ item.createTime }}</time>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "zbItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {

        }
    },
    created () { },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.zb-item {
    position: relative;
    padding: 30px;
    cursor: pointer;
    background: #FFF;
    transition: all 0.3s ease;
    text-decoration: none;

    &::after {
        content: '';
        height: 1px;
        background: rgba(46, 48, 51, .1);
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
    }

    &:hover {
        z-index: 1;
        box-shadow: 0px 3px 6px 1px #EEE8E8;
        border-radius: 10px 10px 10px 10px;

        &::after {
            display: none;
        }

        h3 {
            color: #f15953;
        }

        img {
            transform: scale(1.04);
        }
    }

    .zb-item-img {
        width: 120px;
        height: 120px;
        flex-shrink: 0;
        border-radius: 10px;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s ease .1s;
        }
    }

    h3 {
        color: #2E3033;
        font-size: 18px;
        margin-bottom: 10px;
        transition: all 0.3s ease;
    }

    .desc {
        color: rgba(46, 48, 51, .6);
        font-size: 16px;
    }

    .attachment {
        color: #2E3033;
        margin-top: 15px;

        span {
            color: #f15953;
        }
    }

    .footer {
        margin-top: 15px;
        color: rgba(46, 48, 51, .6);
        font-size: 16px;

        time {
            display: block;



        }
    }

}
</style>