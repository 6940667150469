<template>
    <div>
        <headers />

        <div class="container">

            <el-card>
                <div slot="header">
                    <div class="flex align-items">
                        <img src="../../assets/img/2.png" width="40px" height="40px" />
                        <span class="ml-15" style="font-size:18px;color:#2E3033;font-weight: 700;">建北峰科技股份有限公司</span>
                        <span class="ml-30">销售电话：0551-12547852</span>
                        <span class="ml-30">售后电话：0551-12547852</span>
                    </div>
                </div>
                <img class="w-100" src="../../assets/img/3ea6beec64369c2642b92.png">

                <el-row class="mt-20">
                    <el-button v-for="category in categorys" :key="category.id"
                        :type="category.id === activeIndex ? 'danger' : 'default'" @click="activeIndex = category.id">{{
                            category.name }}
                    </el-button>
                </el-row>

                <template v-if="activeIndex === 1">
                    <zb :zbList="zbList" :treeData="treeData" />
                </template>

                <template v-if="activeIndex === 2">
                    <intor />
                </template>


                <template v-if="activeIndex === 3">
                    <div class="hd-title">
                        <h3>装备视频</h3>
                        <p>Equipment video</p>
                    </div>
                    <div class="video-box">
                        <videoItem v-for="(item, index) in videoList" :key="index" :item="item" />
                    </div>

                    <div class="hd-title">
                        <h3>装备图片</h3>
                        <p>Equipment pictures</p>
                    </div>
                    <div class="video-box">
                        <videoItem v-for="(item, index) in videoList" :key="index" :item="item" />
                    </div>
                </template>

                <template v-if="activeIndex === 4">
                    <zbItem v-for="(item, index) in planList" :key="index" :item="item" />
                </template>
            </el-card>
        </div>
        <footers />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
import videoItem from './components/videoItem.vue'
import zb from './components/zb.vue'
import zbItem from './components/zbItem.vue'
import intor from './components/intor.vue'

import { getZbList, getPlan, getZbVideo } from "@/api/products"

export default {
    name: "Procucts",
    components: {
        headers,
        footers,
        videoItem,
        zb,
        zbItem,
        intor
    },
    data () {
        return {
            activeIndex: 4,
            categorys: [
                {
                    id: 1,
                    name: '供应商装备'
                },
                {
                    id: 2,
                    name: '供应商介绍'
                },
                {
                    id: 3,
                    name: '装备展示'
                },
                {
                    id: 4,
                    name: '场景方案'
                }
            ],
            treeData: [{
                label: '一级 1',
                children: [{
                    label: '二级 1-1',
                    children: [{
                        label: '三级 1-1-1'
                    }]
                }]
            }, {
                label: '一级 2',
                children: [{
                    label: '二级 2-1',
                    children: [{
                        label: '三级 2-1-1'
                    }]
                }, {
                    label: '二级 2-2',
                    children: [{
                        label: '三级 2-2-1'
                    }]
                }]
            }, {
                label: '一级 3',
                children: [{
                    label: '二级 3-1',
                    children: [{
                        label: '三级 3-1-1'
                    }]
                }, {
                    label: '二级 3-2',
                    children: [{
                        label: '三级 3-2-1'
                    }]
                }]
            }],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            videoList: [],

            zbList: [],
            planList: []
        }
    },
    created () {

    },
    mounted () {
        this.searchZb()
        this.searchPlan()
        this.searchZbVideo()
    },
    methods: {
        async searchZb () {
            const res = await getZbList()
            this.zbList = res.data.data.list
        },
        async searchPlan () {
            const res = await getPlan()
            this.planList = res.data.data.list
        },
        async searchZbVideo () {
            const res = await getZbVideo()
            this.videoList = res.data.data.list
        }
    }
}
</script>

<style lang="less" scoped>
.hd-title {
    text-align: center;
    margin-top: 60px;

    h3 {
        font-size: 28px;
        font-weight: 700;
        color: #2E3033;
    }

    p {
        font-size: 14px;
        color: rgba(46, 48, 51, .6);
        margin-top: 10px;
    }
}

/deep/.el-input__suffix {
    right: 0;
}

.orderBy {
    .el-button {
        border-color: transparent;

        &.el-button--danger:hover {
            color: #fff;
        }

        &:hover {
            color: #000;
            background-color: transparent;
        }
    }
}

.video-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    margin-top: 30px;
}
</style>