import { render, staticRenderFns } from "./zbItem.vue?vue&type=template&id=cfb639bc&scoped=true"
import script from "./zbItem.vue?vue&type=script&lang=js"
export * from "./zbItem.vue?vue&type=script&lang=js"
import style0 from "./zbItem.vue?vue&type=style&index=0&id=cfb639bc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfb639bc",
  null
  
)

export default component.exports