<template>
    <el-row :gutter="30" class="mt-40">
        <el-col :span="4">
            <div style="border-bottom:1px solid rgba(46, 48, 51, .1);margin-bottom: 15px;">
                <img src="../../../assets/img/139.png" />
                <img src="../../../assets/img/140.png" class="ml-10" />
            </div>
            <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="20">
            <div class="flex align-items space-between mb-20">
                <div class="orderBy">
                    <el-button v-for="(item, index) in orderByList" :key="index"
                        :type="item.val === orderBy ? 'danger' : 'default'" @click="orderBy = item.val">{{
                            item.name }}
                    </el-button>
                </div>
                <el-form>
                    <el-input placeholder="请输入装备名称" v-model="zbName">
                        <template slot="suffix">
                            <el-button type="danger">搜索</el-button>
                        </template>
                    </el-input>
                </el-form>
            </div>
            <zbItem v-for="(item, index) in zbList" :key="index" :item="item" />

            <div class="text-center mt-20">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper" :total="400">
                </el-pagination>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import zbItem from './zbItem.vue'

import { getZbList } from "@/api/products"

export default {
    name: "zb",
    components: {
        zbItem
    },
    props: {
        zbList: {
            type: Array,
            default: () => []
        },
        treeData: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            orderBy: 1,
            orderByList: [
                {
                    val: 1,
                    name: '综合'
                },
                {
                    val: 2,
                    name: '浏览量'
                },
                {
                    val: 3,
                    name: '销量'
                },
                {
                    val: 4,
                    name: '最新发布'
                }
            ],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            // 搜索装备名称
            zbName: '',
            currentPage: 1,
        }
    },
    created () {

    },
    mounted () {
    },
    methods: {
        handleNodeClick (data) {
            console.log(data)
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
        }
    }
}
</script>

<style lang="less" scoped>
.nav {
    display: grid;
    grid-template-columns: repeat(4, minmax(25%, 1fr));

    .nav-item {
        position: relative;
        text-align: center;
        padding: 15px;
        font-size: 20px;
        color: #fff;
        background: #f15953;
        cursor: pointer;

        &:not(:last-child) {
            &::after {
                content: '';
                position: absolute;
                top: 10%;
                right: 0;
                width: 2px;
                height: 80%;
                background-color: #fff;
            }
        }

        h3 {
            position: relative;
            font-weight: 500;
            overflow: hidden;

            &::before {
                content: '\e791';
                font-family: element-icons !important;
                font-size: 14px;
                position: absolute;
                left: -20px;
                /* 初始位置在左侧外部 */
                top: 50%;
                transform: translateY(-50%);
                transition: left 0.5s ease;
                /* 淡入动画 */
            }

            &::after {
                content: '\e792';
                font-family: element-icons !important;
                font-size: 14px;
                position: absolute;
                right: -20px;
                /* 初始位置在右侧外部 */
                top: 50%;
                transform: translateY(-50%);
                transition: right 0.5s ease;
                /* 淡入动画 */
            }
        }

        &.active {
            background: #ef312a;
        }

        &:hover,
        &.active {
            h3 {
                &::before {
                    left: 30%;
                }

                &::after {
                    right: 30%;
                }
            }
        }
    }
}

.hd-title {
    text-align: center;
    margin-top: 30px;

    h3 {
        font-size: 28px;
        font-weight: 700;
        color: #2E3033;
    }

    p {
        font-size: 14px;
        color: rgba(46, 48, 51, .6);
        margin-top: 10px;
    }
}

/deep/.el-input__suffix {
    right: 0;
}

.orderBy {
    .el-button {
        border-color: transparent;

        &.el-button--danger:hover {
            color: #fff;
        }

        &:hover {
            color: #000;
            background-color: transparent;
        }
    }
}

.video-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    margin-top: 30px;
}
</style>