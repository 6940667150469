<template>
    <div class="video-item">
        <div class="video-img">
            <el-image :src="item.pic" lazy></el-image>
            <div class="video-play-icon">
                <i class="iconfont icon-bofang"></i>
            </div>
        </div>
        <div class="video-body">
            <div style="width:calc(100% - 32px)">
                <div class="title text-hide">{{ item.title }}</div>
                <p><i class="el-icon-view"></i>{{ item.hits }}</p>
            </div>
            <div class="jiantou">
                <i class="iconfont icon-jiantou"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "videoItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {

        }
    },
    created () { },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.video-item {
    width: 260px;
    height: 346px;
    background: #FFF;
    box-shadow: 0px 3px 6px 1px #EEE8E8;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;

    .video-img {
        position: relative;
        width: 100%;
        height: 260px;

        .el-image {
            width: 100%;

            img {
                display: block;
                width: 100%;
                height: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }

        .video-play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60px;
            height: 60px;
            transform: translate(-50%, -50%);

            i {
                font-size: 50px;
                color: rgba(255, 255, 255, .8);
                transition: all.3s ease;
            }
        }

    }

    .video-body {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #2E3033;
            font-size: 16px;
            margin-bottom: 10px;
            transition: all.3s ease;
        }

        p {
            color: rgba(46, 48, 51, .6);
            font-size: 14px;

            i {
                margin-right: 5px;
            }
        }

        .jiantou {
            display: flex;
            align-items: center;
            width: 32px;
            height: 100%;
            flex-shrink: 0;

            i {
                font-size: 30px;
                color: rgba(46, 48, 51, .2);
                transition: all.3s ease;
            }
        }
    }

    &:hover {
        .video-img {
            .video-play-icon {
                i {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }

        .video-body {
            .title {
                color: #ef312a;
            }

            .jiantou {

                i {
                    font-size: 30px;
                    color: #ef312a;
                }
            }
        }
    }
}
</style>